<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header v-show="categoryMode" ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
                <p v-if="!categoryMode" class="title">{{ getCommonText('decoding') }}. {{ currCategory.name_without_order_number }}</p>
            </div>
            <div class="right-content">
                <c-budget-forms-data-transfer
                    v-if="categoryMode & !isGkkpMode && !(progress < 100) && variantAttribute"
                    :header="header"
                    :lng="lng"
                    :getDataTransferText="getDataTransferText"
                    :getErrText="getErrText"
                    :makeToast="makeToast"
                    @reloadTable="loadCategoryData"
                />
                <div class="filter-actions filter-actions-flex">
                    <template v-if="categoryMode">
                        <c-budget-forms-copy-data 
                            v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                            :budgetForm="budgetForm"
                            :header="header"
                            :total="total"
                            :isLoad="isLoad"
                            @keyPress="keyPress"
                        />
                    </template>
                    <template v-else>
                        <b-button variant="light" @click="resetModal">
                           <i class="icon icon-keyboard icon-rotate-180"></i> {{ getCommonText('back_to_list') }}
                        </b-button>
                        <b-button :style="{ 'minWidth': '110px' }" v-if="variantAttribute" variant="primary" @click="$refs.formTemplate.addItem()">
                            <i class="icon icon-plus-circle"></i>{{ getCommonText('add') }}
                        </b-button>
                        <b-button
                            :style="{ 'minWidth': '110px' }" 
                            :disabled="!dataChanged || goodsAreSaving"
                            variant="success"
                            @click="prepareForSave">
                            {{ getCommonText('save') }}
                        </b-button>
                    </template>
                </div>
            </div>
        </div>

        <breadcrumbs-filter v-show="categoryMode" :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div v-show="categoryMode" class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering table-primary">8</td>
                    <td class="td-numbering table-info">9</td>
                    <td class="td-numbering table-warning">10</td>
                    <td class="td-numbering table-primary">11</td>
                    <td class="td-numbering table-warning">12</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(selection)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAll" 
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAll ? getCommonText('del_with_decodes', {msg: getCommonText('all_cats')}) : getCommonText('del_with_decodes', {msg: getCommonText('selected_cats')})}`)"/>
                    </div>
                </template>
                <template #cell(selection)="data">
                    <b-form-checkbox 
                        v-if="!hasChild(data.item.code)"
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(action)="data">
                    <button 
                        v-if="!hasChild(data.item.code)"
                        class="table-plus"
                        @click="setCurrCatName(data.item)"
                    >
                        <i class="icon icon-plus-circle"/>
                    </button>
                </template>
                <template #cell(name)="data">
                    <div>
                        {{ data.item.name }}
                    </div>
                </template>
                <template #cell(unit)="data">
                    <div class="text-center">
                        {{ data.item.unit }}
                    </div>
                </template>
                <template #cell(amount)="data">
                    <template v-if="!hasChild(data.item.code)">
                        <div class="text-right">{{ $n(data.value) }}</div>
                    </template>
                </template>
                <template #cell(abonent)="data">
                    <template v-if="!hasChild(data.item.code)">
                        <div class="text-right">
                            {{ data.item.abonent === null ? defaultSymbol : $n(data.item.abonent) }}
                        </div>
                    </template>
                </template>
                <template #cell(time_based)="data">
                    <template v-if="!hasChild(data.item.code)">
                        <div class="text-right">
                            {{ data.item.time_based === null ? defaultSymbol : $n(data.item.time_based) }}
                        </div>
                    </template>
                </template>
                <template #cell(payment)="data">
                    <template v-if="!hasChild(data.item.code)">
                        <div class="text-right">
                            {{ data.item.payment === null ? defaultSymbol : $n(data.item.payment) }}
                        </div>
                    </template>
                </template>
                <template #cell(cost)="data">
                    <template v-if="!hasChild(data.item.code)">
                        <div class="text-right">
                            {{ data.item.cost === null ? defaultSymbol : $n(data.item.cost) }}
                        </div>
                    </template>
                </template>
                <template #cell(months)="data">
                    <template v-if="!hasChild(data.item.code)">
                        <div class="text-right">
                            {{ data.item.months === null ? defaultSymbol : $n(data.item.months) }}
                        </div>
                    </template>
                </template>
                <template #cell(rent)="data">
                    <template v-if="!hasChild(data.item.code)">
                        <div class="text-right">
                            {{ data.item.rent === null ? defaultSymbol : $n(data.item.rent) }}
                        </div>
                    </template>
                </template>
                <template #cell(traffic)="data">
                    <template v-if="!hasChild(data.item.code)">
                        <div class="text-right">
                            {{ data.item.traffic === null ? defaultSymbol : $n(data.item.traffic) }}
                        </div>
                    </template>
                </template>
                <template #cell(total)="data">
                    <div class="text-right">
                        {{ $n(data.value) }}
                    </div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onAllFilesClick({...data.item, name_ru: data.item.name})"
                    >({{data.item.row_files.length}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_rec')"
                            class="icon icon-clear table-remove" 
                            v-if="variantAttribute" 
                            @click="deleteItem(`${getCommonText('del_with_decodes', {msg: getCommonText('curr_cat')})}`, data.item, data.index)"
                        ></i>
                    </div>
                </template>

                <template #bottom-row="data">
                    <td class="text-right" colspan="12">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="3"></td>
                </template>
            </b-table>
        </div>

       <template v-if="!categoryMode">
            <b-overlay :show="templateTableOverlay" rounded="sm">
                <budget-forms-template-7
                    ref="formTemplate"
                    :formCode="form.code"
                    :goodsData="goodsData"
                    :header="header"
                    :initialGoodsData="initialGoodsData"
                    :ensTruDict="ensTruDict"
                    :variantAttribute="variantAttribute"
                    :currCategory="currCategory"
                    :lang="lang"
                    :ifAllFieldsFilled="ifAllFieldsFilled"
                    :deleteGood="deleteGood"
                    :getUnitName="getUnitName"
                    :ensTruNamePrt="ensTruNamePrt"
                    :ensTruName="ensTruName"
                    :currUnits="currUnits"
                    :descIsLoading="descIsLoading"
                    :parNameLoading="parNameLoading"
                    :allFiles="allFiles"
                    @setTotals="setTotals"
                    @updateDatas="updateDatas"
                    @save="save"
                    @searchName="searchName"
                    @setIsAllDelete="setIsAllDelete"
                    @deleteSeveralGoods="deleteSeveralGoods"
                    @loadCategoryData="loadCategoryData"
                    @setDataChanged="setDataChanged"
                    @triggDownloadFile="triggDownloadFile"
                    @triggDownloadAllFiles="triggDownloadAllFiles"
                    @fileUpload="fileUpload"
                    @doublesValidationDecodes="doublesValidationDecodes"
                    @deletingAgreementTotalResultHandler="deletingAgreementTotalResultHandler"
                    :roundNumber="roundNumber"
                    :showFileUploadModal="showFileUploadModal"
                    :getEnstruByName="getEnstruByName"
                    :loadCurUnits="loadCurUnits"
                    :goodsAreSaving="goodsAreSaving"
                    :isDoubleDecodeExist="isDoubleDecodeExist"
                ></budget-forms-template-7>
                <div class="table-add" v-if="variantAttribute">
                    <span @click="$refs.formTemplate.addItem()"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
                </div>
            </b-overlay>
        </template>
        
        <files-updown ref="filesUpdown"
            v-show="categoryMode"
            :header="header"
            :variant-attribute="variantAttribute"
            :load="isLoad"
            :showDeleteAll="true"
            @getFiles="getFiles"
            @getNewFiles="getNewFiles"
            @delFile="delFile"
            @restetFilesList="restetFilesList"
            @onFilePreview="onFilePreview"
        ></files-updown>
        <modal-all-files-management
            ref="modalAllFilesManagement"
            :allFiles="allFiles"
            :hasFilesTable="true"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            >
        </modal-all-files-management>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import ModalAllFilesManagement from './components/modal-all-files-management.vue';
import FormsHandlerMixin from "./mixins/forms-handler-mixin";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import BudgetFormsTemplate7 from "./components/budget-forms-template7.vue";
import CBudgetFormsDataTransfer from './components/budget-forms-data-transfer.vue';

export default {
    name: 'Form01_152',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        FormsDownloadReprt, 
        BreadcrumbsFilter, 
        ModalAllFilesManagement, 
        CBudgetFormsCopyData, 
        BudgetFormsTemplate7,
        CBudgetFormsDataTransfer
    },
    mixins: [FormsHandlerMixin],
    data() {
        return {
            form: {
                code: '01-152',
                name_ru: 'Расчет расходов на оплату услуг связи',
                name_kk: 'Байланыс қызметтерiне ақы төлеуге жұмсалған шығыстарды есептеу'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dict: null,
            files: null,
            // load: true,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: 1,
            defaultSymbol: 'x',
            parentCatsCodes: ['07', '15', '18', '20']
        };
    },

    async mounted() {
        await this.loadDict();
    },

    methods: {
        deleteItem(msg, row = false) {
            if (!this.variantAttribute || this.isLoad 
                || (!row && this.budgetForm.findIndex(itm => itm.itemToDelete) === -1)) return;
            this.$bvModal.msgBoxConfirm(
                msg,
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            this.deleteCategorys([row.code]);
                        } else {

                            const categoryIdsToDel = [];
                            this.budgetForm.forEach(cat => {
                                if (cat.itemToDelete) {
                                    categoryIdsToDel.push(cat.code);
                                }
                            })
                            if (categoryIdsToDel.length) this.deleteCategorys(categoryIdsToDel);
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getErrText('on_del'), error.toString());
                });
        }, // очистить значения строки

        async deleteCategorys(categoryIdsToDel) {
            this.isLoad = true;
            const dataForDeleting = {
                categoryIdsToDel,
                header: this.header,
                hasFilesTable: true,
                categoryField: 'communication'
            };
            try {
                const response = await fetch('/api-py/delete-budget-request-form-by-category/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataForDeleting)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    await this.loadCategoryData();
                    this.deletingAgreementTotalResultHandler(result);
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('deleted'));
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
            } finally {
                this.isLoad = false;
            }
        }, // удаление данных

        getFiles(data) {
            this.files = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        input12(item, field, value) {
            // nextTick добавлен тк не без него не работает реактивность при событии keyPress.enter если предыдущее значение = 0 
            this.$nextTick(() => {
                const newVal = parseFloat(value) ? this.roundNumber(value, 0) : 0;
                if (parseInt(newVal) > 12) {
                    this.$set(item, field, 0);
                    this.makeToast('danger', this.getErrText('warning'), this.getErrText('not_more_12'));
                } else {
                    this.$set(item, field, newVal);
                }
            })
        }, // проверка value>12

        async itemUpdate(item) {
            this.$set(item, 'amount', 0);
            this.$set(item, 'abonent', 0.0);
            this.$set(item, 'time_based', 0.0);
            this.$set(item, 'payment', 0.0);
            this.$set(item, 'cost', 0.0);
            this.$set(item, 'months', 0);
            this.$set(item, 'rent', 0.0);
            this.$set(item, 'traffic', 0.0);
            this.$set(item, 'files', 0);
            this.$set(item, 'row_files', [])
            this.isLoad = false;
        },

        keyPress(event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13(event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadCategoryData() {
            this.isLoad = true;

            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                if (response.status === 200) {
                    values = await response.json();
                } else {
                    this.budgetForm.splice(0);
                    this.makeToast('danger', `${this.getErrText('bad_request')} loadCategoryData()`, `Error code: ${response.status}`);
                }
            } catch (error) {
                this.budgetForm.splice(0);
                this.makeToast('danger', `${this.getErrText('bad_request')} loadCategoryData()`, error.toString());
            }

            const newBudgetForm = [];
            this.fillWithDictAndDefaultData(newBudgetForm);

            this.addToNewBudgetFormDataFromValues(newBudgetForm, values);

            this.budgetForm.splice(0);
            this.budgetForm = newBudgetForm;
            this.isLoad = false;
        },

        fillWithDictAndDefaultData(newBudgetForm) {
            const that = this;
            for (const row of that.dict) {
                const item = {
                    id: this.newRowStartId,
                    id_dict: row.id,
                    par_id: row.par_id,
                    code: row.code,
                    itemToDelete: false,
                    unit: row.unit,
                    total: 0
                };
                Object.defineProperty(item, 'name', {
                    get: function() {
                        const rowNum = row.order !== null ? row.order + '. ' : '';
                        if (that.lng) return rowNum + row[`name_${that.lng}`]
                        return rowNum
                    }
                })
                Object.defineProperty(item, 'name_without_order_number', {
                    get: function() {
                        if (that.lng) return row[`name_${that.lng}`]
                        return rowNum
                    }
                })
                Object.defineProperty(item, 'unit', {
                    get: function() {
                        if (that.lng === 'kk') return row.unit_kk;
                        return row.unit
                    }
                })
                this.newRowStartId++;

                that.itemUpdate(item);

                if (this.parentCatsCodes.includes(row.code)) {
                    Object.defineProperty(item, 'total', {
                        get: function () {
                            return that.reSum(item, 'total');
                        }
                    });
                } 
                
                newBudgetForm.push(item);
            }
        },

        addToNewBudgetFormDataFromValues(newBudgetForm, values) {
            values.forEach(val => {
                const itemExist = newBudgetForm.find(item => item.code === val.communication);
                if (itemExist) {
                    itemExist.amount = val.sum_amount;
                    itemExist.abonent = val.sum_abonent;
                    itemExist.time_based = val.sum_time_based;
                    itemExist.payment = val.sum_payment;
                    itemExist.cost = val.sum_cost;
                    itemExist.months = val.max_months;
                    itemExist.rent = val.sum_rent;
                    itemExist.files = val.files;
                    itemExist.row_files = val.row_files;
                    itemExist.traffic = val.sum_traffic;
                    itemExist.total = val.total;
                }
            });
        },

        async loadDict() {
            try {
                const response = await fetch('/api-py/dictionary/communications/');
                this.dict = await response.json();
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDict()`, error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) {
                s = '0' + s;
            }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        fillDataForSave(item, row) {
            this.$set(item, 'communication', this.currCategory.code);
            this.$set(item, 'amount', parseInt(row.amount));
            this.$set(item, 'abonent', parseFloat(row.abonent));
            this.$set(item, 'time_based', parseFloat(row.time_based));
            this.$set(item, 'payment', parseFloat(row.payment));
            this.$set(item, 'cost', parseFloat(row.cost));
            this.$set(item, 'months', parseInt(row.months));
            this.$set(item, 'rent', parseFloat(row.rent));
            this.$set(item, 'traffic', parseFloat(row.traffic));
            this.$set(item, 'row_files', row.row_files);
            
        }, // заполнение полей при подготовке к сохранению товаров

        updateTotalBeforeSave(isCatDeleting = false, prop = 'totalAll') {
            // Не удалять, заглушка для миксина
        },

        ifAllFieldsFilled() {
            const prevNewItemIdx = this.goodsData.findIndex(item => item.isNew === true);
            for (const [index, item] of this.goodsData.entries()) {
                const rowNumb = index + 1;
                if (!item.enstru_code) {
                    this.makeToast('warning', this.getCommonText('attention'), this.getDecText('not_valid', {fieldName: this.getCommonText('name'), rowNmb: rowNumb}));
                    return false;
                };
                if (!item.amountPassVal) {
                    this.makeToast('warning', this.getCommonText('attention'), this.getDecText('not_valid', {fieldName: this.getFormText('num_count'), rowNmb: rowNumb}));
                    return false;
                }
                if (!item.monthsPassVal) {
                    this.makeToast('warning', this.getCommonText('attention'), this.getDecText('not_valid', {fieldName: this.getFormText('month_count'), rowNmb: rowNumb}));
                    return false;
                }
                if (!item.costColsPassVal) {
                    this.makeToast('warning', this.getCommonText('attention'), this.getDecText('not_valid', {fieldName: '№ 4 - 7, 9, 10', rowNmb: rowNumb}));
                    return false;
                }
            }
            if (prevNewItemIdx !== -1) this.$set(this.goodsData[prevNewItemIdx], 'isNew', false);
            return true
        }, // проверка заполненности полей у расшифровках
        
        reSum(parent, field) {
            let sum = 0;
            this.budgetForm.forEach(row => {
                if (row.par_id === parent.id_dict) {
                    sum += parseFloat(row[field]);
                }
            });
            return this.roundNumber(sum, 2);
        }, // пересчет суммы

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        async save(values) {
            this.isLoad = true;
            try {
                // const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : `/api-py/save_brform${this.form.code.replace('-', '_')}/`
                const response = await fetch(`/api-py/save_brform${this.form.code.replace('-', '_')}/` + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                if (response.status === 200) {
                    const result = await response.json();
                    this.addSavedIdsToGoodsData(result);
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.selectAll = false;
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    this.loadCategoryData();
                } else {
                    this.selectAll = false;
                    this.loadCategoryData();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
                this.dataChanged = false;
                this.goodsAreSaving = false;
            }
        }, // сохранение данных

        addSavedIdsToGoodsData(result) {
            if (!result.oldId_newId) return;
            this.goodsData.forEach(item => {
                const newId = result.oldId_newId[item.id.toString()];
                if (newId && newId !== item.id) {
                    this.$set(item, 'id', newId);
                };

                const newFilesIds = result.new_files_ids[item.id.toString()];
                if (newFilesIds && Object.keys(newFilesIds).length !== 0) {
                    this.addSavedFilesIds(item.row_files, newFilesIds)
                };

            });
        },

        addSavedFilesIds(rowFiles, newFilesIds) {
            rowFiles.forEach(file => {
                const newId = newFilesIds[file.file_id.toString()];
                if (newId) {
                    this.$set(file, 'id', newId);
                }
            })
        },

        hasChild(code) {
            return this.parentCatsCodes.includes(code);
        },

        setTotals(val) {
            Object.defineProperty(val, 'total', {
                get: () => {
                    const total = ((val.abonent + val.time_based +
                                        val.cost +
                                        val.rent + val.traffic) * val.months +
                                        val.payment
                                    ) * val.amount / 1000;
                    const totalRounded = this.roundNumber(total, 2);
                    return totalRounded;
                }
            })
        },

        setColsForForm01_152(val) {
            this.$set(val, 'amountPassVal', true);
            this.$set(val, 'costColsPassVal', true);
            this.$refs.formTemplate.validateMonthsField(val);
        },

        triggDownloadAllFiles(filesList) {
            const fileNamesList = filesList.reduce((arr, item) => {
                if (typeof item === 'string') {
                    arr.push(item);
                } else {
                    arr.push(item.file_name);
                }
                return arr;
            }, []
                );
            this.$refs.filesUpdown.downloadAllFileReqst(fileNamesList);
        }, // инициация скачивания прикрепленных файлов

        fileUpload(rowId) {
            this.$refs.filesUpdown.openModalFileUpload();
            this.$refs.filesUpdown.setRowId(rowId);
        },

        onAllFilesClick(item) {
            this.allFiles = item.hasOwnProperty('row_files') ? item.row_files : [];
            this.$refs.modalAllFilesManagement.showModal(item);
        },

            getNewFiles(files) {
            if (!files.length || !this.$refs.formTemplate) return;
            this.goodsData.forEach(item => {
            files.forEach((file) => {
                const newFile = JSON.parse(JSON.stringify(file));
                const isNotSelectedRow = file.row_id !== item.id;
                if (isNotSelectedRow) {
                    newFile.row_id = null;
                    item['row_files'] = [...item['row_files'], newFile];
                }
            })
            })
            this.$refs.formTemplate.onNewFileAdded(files);
        },

        delFile(file_id) {
            this.budgetForm.forEach(item => {
                item.row_files = item.row_files.filter(file => file.file_id !== file_id);
                item.files = item.row_files.length;
            });
        },

        restetFilesList() {
            // не удалять
        },
    },

    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (row.par_id === null) {
                    sum += parseFloat(row.total);
                }
            }
            return Math.ceil(sum);
        },

        getFormText() {
            return this.setFormText('form_01_152.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'selection',
                        label: ' '
                    },
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'name',
                        label: this.getCommonText('name')
                    },
                    {
                        key: 'unit',
                        label: this.getUnitsText('measure')
                    },
                    {
                        key: 'amount',
                        label: this.getFormText('num_count')
                    },
                    {
                        key: 'abonent',
                        label: this.getFormText('sub_fee')
                    },
                    {
                        key: 'time_based',
                        label: this.getFormText('time_fee')
                    },
                    {
                        key: 'payment',
                        label: this.getFormText('annual_channel_cost')
                    },
                    {
                        key: 'cost',
                        label: this.getFormText('monthly_avg_cost')
                    },
                    {
                        key: 'months',
                        label: this.getFormText('month_count')
                    },
                    {
                        key: 'rent',
                        label: this.getFormText('monthly_rent_fee')
                    },
                    {
                        key: 'traffic',
                        label: this.getFormText('monthly_rate_fee')
                    },
                    {
                        key: 'total',
                        label: this.getFormText('expense_sum')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        },
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
    .table-plus {
        border: none;
    }
</style>